// FoodRecipeFooter.js
import React from 'react';

const FoodRecipeFooter = () => {
  return (
    <footer className="bg-gray-900 text-gray-200 py-10">
      <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-8">
        
        {/* About Section */}
        <div>
          <h2 className="text-2xl font-semibold mb-4">About Us</h2>
          <p className="text-gray-400">
            Discover delicious recipes curated by chefs from around the world. Join our community of food lovers and explore new flavors every day!
          </p>
        </div>

        {/* Quick Links */}
        <div>
          <h2 className="text-2xl font-semibold mb-4">Quick Links</h2>
          <ul className="space-y-2">
            <li><a href="#home" className="hover:underline">Home</a></li>
            <li><a href="#recipes" className="hover:underline">Recipes</a></li>
          </ul>
        </div>

        {/* Newsletter Subscription */}
        <div>
          <h2 className="text-2xl font-semibold mb-4">Subscribe to Our Newsletter</h2>
          <p className="text-gray-400 mb-4">Get weekly recipes and cooking tips delivered straight to your inbox.</p>
          <form className="flex flex-col sm:flex-row">
            <input
              type="email"
              placeholder="Enter your email"
              className="p-2 rounded-l-md bg-gray-800 text-gray-200 border border-gray-700 focus:outline-none"
            />
            <button
              type="submit"
              className="p-2 mt-2 sm:mt-0 sm:ml-2 bg-blue-600 rounded-md text-white font-semibold hover:bg-blue-700 transition duration-200"
            >
              Subscribe
            </button>
          </form>
        </div>
      </div>

      {/* Social Media Links */}
      <div className="border-t border-gray-700 mt-10 pt-6">
        <div className="container mx-auto px-4 flex flex-col sm:flex-row justify-between items-center">
          <p className="text-sm text-gray-500 mb-4 sm:mb-0">&copy; 2024 Food Recipes. All rights reserved.</p>
          <div className="flex space-x-4">
            <a href="#facebook" className="text-gray-400 hover:text-gray-200"><i className="fab fa-facebook-f"></i></a>
            <a href="#twitter" className="text-gray-400 hover:text-gray-200"><i className="fab fa-twitter"></i></a>
            <a href="#instagram" className="text-gray-400 hover:text-gray-200"><i className="fab fa-instagram"></i></a>
            <a href="#youtube" className="text-gray-400 hover:text-gray-200"><i className="fab fa-youtube"></i></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FoodRecipeFooter;
