import React from 'react'
import Navbar from './component/navbar'
import HomePage from './component/Home'
import FoodRecipeFooter from './component/Footer'
import RecipeGenerator from './component/recipe'
import { BrowserRouter as Router , Routes, Route } from 'react-router-dom'


const App = () => {
  return (
    <div>
      <Router>
      <Navbar/>
      <Routes>
      <Route path='/' element={<HomePage/>} />
      <Route path='/Home' element={<RecipeGenerator/>}/>
      </Routes>
      <FoodRecipeFooter/>
      </Router>
      </div>
  )
}

export default App