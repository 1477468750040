// RecipeGenerator.js
// import React, { useState } from 'react';
// import axios from 'axios';

// function RecipeGenerator() {
//   const [query, setQuery] = useState('');
//   const [recipe, setRecipe] = useState(null);
//   const [error, setError] = useState(null);

//   // Fetch recipe from API
//   const fetchRecipe = async () => {
//     try {
//       const response = await axios.get(`https://www.themealdb.com/api/json/v1/1/search.php?s=${query}`);
//       const data = response.data.meals;

//       if (data) {
//         setRecipe(data[0]); // Take the first matching recipe
//         setError(null); // Clear any previous error
//       } else {
//         setRecipe(null);
//         setError('No recipe found. Try another search.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching the recipe.');
//       setRecipe(null);
//     }
//   };

//   // Handle search button click
//   const handleSearch = () => {
//     if (query.trim()) {
//       fetchRecipe();
//     }
//   };

//   return (
//     <div style={{ textAlign: 'center', maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
//       <h1>AI Recipe Generator</h1>
//       <input
//         type="text"
//         value={query}
//         onChange={(e) => setQuery(e.target.value)}
//         placeholder="Enter a dish name (e.g., Arrabiata)"
//         style={{ padding: '10px', width: '80%', marginBottom: '10px' }}
//       />
//       <button onClick={handleSearch} style={{ padding: '10px 20px', cursor: 'pointer' }}>
//         Search
//       </button>

//       {error && <p style={{ color: 'red' }}>{error}</p>}

//       {recipe && (
//         <div style={{ marginTop: '20px' }}>
//           <h2>{recipe.strMeal}</h2>
//           <img src={recipe.strMealThumb} alt={recipe.strMeal} style={{ width: '50%', borderRadius: '8px' }} />

//           <h3>Ingredients</h3>
//           <ul style={{ maxHeight: '150px', overflowY: 'auto', textAlign: 'left', padding: '10px', border: '1px solid #ddd', borderRadius: '8px', marginBottom: '20px' }}>
//             {[...Array(20)].map((_, i) => {
//               const ingredient = recipe[`strIngredient${i + 1}`];
//               const measure = recipe[`strMeasure${i + 1}`];
//               return ingredient ? (
//                 <li key={i}>
//                   {measure} {ingredient}
//                 </li>
//               ) : null;
//             })}
//           </ul>

//           <h3>Instructions</h3>
//           <div style={{ maxHeight: '200px', overflowY: 'auto', padding: '10px', border: '1px solid #ddd', borderRadius: '8px' }}>
//             <p>{recipe.strInstructions}</p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default RecipeGenerator;


// RecipeGenerator.js
import React, { useState } from 'react';
import axios from 'axios';

function RecipeGenerator() {
  const [letter, setLetter] = useState('');
  const [recipes, setRecipes] = useState([]);
  const [error, setError] = useState(null);

  // Fetch recipes by first letter
  const fetchRecipes = async () => {
    try {
      const response = await axios.get(`https://www.themealdb.com/api/json/v1/1/search.php?f=${letter}`);
      const data = response.data.meals;

      if (data) {
        setRecipes(data);
        setError(null);
      } else {
        setRecipes([]);
        setError('No recipes found for this letter. Try another.');
      }
    } catch (error) {
      setError('An error occurred while fetching recipes.');
      setRecipes([]);
    }
  };

  // Handle search button click
  const handleSearch = () => {
    if (letter.trim() && /^[a-zA-Z]$/.test(letter)) {
      fetchRecipes();
    } else {
      setError('Please enter a single alphabet letter.');
    }
  };

  return (
    <>
     <div className="bg-gray-100 ">
      {/* Hero Section */}
      <header className="bg-cover bg-center h-96" style={{ backgroundImage: `url('https://source.unsplash.com/featured/?food')` }}>
        <div className="bg-black bg-opacity-50 h-full flex items-center justify-center">
          <h1 className="text-white text-4xl md:text-6xl font-bold text-center">
            Find Your Recipe with first letter of dish
          </h1>
        </div>
      </header>
     </div>
    
    <div style={{ textAlign: 'center', maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
      <br></br><br></br>
      <h4 className="text-black text-4xl md:text-6xl font-bold text-center">AI Recipe Generator</h4>
      <br></br><br></br>
      <input
        type="text"
        value={letter}
        onChange={(e) => setLetter(e.target.value.toLowerCase())}
        placeholder="Enter a Recipe first Letter"
        maxLength="1"
        className="p-2 rounded-l-md bg-gray-800 text-gray-200 border border-gray-700 focus:outline-none"
      />
      <button onClick={handleSearch} className="p-2 mt-2 sm:mt-0 sm:ml-2 bg-blue-600 rounded-md text-white font-semibold hover:bg-blue-700 transition duration-200">
        Search
      </button>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {recipes.length > 0 && (
        <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
          {recipes.map((recipe) => (
            <div key={recipe.idMeal} style={{ border: '1px solid #ddd', borderRadius: '8px', width: '200px', padding: '10px' }}>
              <h3>{recipe.strMeal}</h3>
              <img src={recipe.strMealThumb} alt={recipe.strMeal} style={{ width: '100%', borderRadius: '8px' }} />
              <p><strong>Category:</strong> {recipe.strCategory}</p>
              <p><strong>Area:</strong> {recipe.strArea}</p>
              <button onClick={() => window.open(recipe.strSource, '_blank')} style={{ marginTop: '10px', padding: '5px 10px' }}>
                View Recipe
              </button>
            </div>
          ))}
        </div>
      )}
      <br></br><br></br><br></br><br></br>
    </div>
    </>
  );
}

export default RecipeGenerator;


// // RecipeGenerator.js
// import React, { useState } from 'react';
// import axios from 'axios';

// function RecipeGenerator() {
//   const [letter, setLetter] = useState('');
//   const [recipes, setRecipes] = useState([]);
//   const [error, setError] = useState(null);

//   // Fetch recipes by first letter
//   const fetchRecipes = async () => {
//     try {
//       const response = await axios.get(`https://www.themealdb.com/api/json/v1/1/search.php?f=${letter}`);
//       const data = response.data.meals;

//       if (data) {
//         setRecipes(data);
//         setError(null);
//       } else {
//         setRecipes([]);
//         setError('No recipes found for this letter. Try another.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching recipes.');
//       setRecipes([]);
//     }
//   };

//   // Handle search button click
//   const handleSearch = () => {
//     if (letter.trim() && /^[a-zA-Z]$/.test(letter)) {
//       fetchRecipes();
//     } else {
//       setError('Please enter a single alphabet letter.');
//     }
//   };

//   return (
//     <div style={{ textAlign: 'center', maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
//       <h1>AI Recipe Generator</h1>
//       <input
//         type="text"
//         value={letter}
//         onChange={(e) => setLetter(e.target.value.toLowerCase())}
//         placeholder="Enter a letter (e.g., a)"
//         maxLength="1"
//         style={{ padding: '10px', width: '60%', marginBottom: '10px' }}
//       />
//       <button onClick={handleSearch} style={{ padding: '10px 20px', cursor: 'pointer' }}>
//         Search
//       </button>

//       {error && <p style={{ color: 'red' }}>{error}</p>}

//       {recipes.length > 0 && (
//         <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
//           {recipes.map((recipe) => (
//             <div key={recipe.idMeal} style={{ border: '1px solid #ddd', borderRadius: '8px', width: '200px', padding: '10px' }}>
//               <h3>{recipe.strMeal}</h3>
//               <img src={recipe.strMealThumb} alt={recipe.strMeal} style={{ width: '100%', borderRadius: '8px' }} />
//               <p><strong>Category:</strong> {recipe.strCategory}</p>
//               <p><strong>Area:</strong> {recipe.strArea}</p>
//               <button onClick={() => window.open(recipe.strSource, '_blank')} style={{ marginTop: '10px', padding: '5px 10px' }}>
//                 View Recipe
//               </button>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// }

// export default RecipeGenerator;


// RecipeGenerator.js
// import React, { useState } from 'react';
// import axios from 'axios';

// function RecipeGenerator() {
//   const [letter, setLetter] = useState('');
//   const [recipes, setRecipes] = useState([]);
//   const [error, setError] = useState(null);

//   // Fetch recipes by first letter
//   const fetchRecipes = async () => {
//     try {
//       const response = await axios.get(`https://www.themealdb.com/api/json/v1/1/search.php?f=${letter}`);
//       const data = response.data.meals;

//       if (data) {
//         setRecipes(data);
//         setError(null);
//       } else {
//         setRecipes([]);
//         setError('No recipes found for this letter. Try another.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching recipes.');
//       setRecipes([]);
//     }
//   };

//   // Handle search button click
//   const handleSearch = () => {
//     if (letter.trim() && /^[a-zA-Z]$/.test(letter)) {
//       fetchRecipes();
//     } else {
//       setError('Please enter a single alphabet letter.');
//     }
//   };

//   return (
//     <div style={{ textAlign: 'center', maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
//       <h1>AI Recipe Generator</h1>
//       <input
//         type="text"
//         value={letter}
//         onChange={(e) => setLetter(e.target.value.toLowerCase())}
//         placeholder="Enter a letter (e.g., a)"
//         maxLength="1"
//         style={{ padding: '10px', width: '60%', marginBottom: '10px' }}
//       />
//       <button onClick={handleSearch} style={{ padding: '10px 20px', cursor: 'pointer' }}>
//         Search
//       </button>

//       {error && <p style={{ color: 'red' }}>{error}</p>}

//       {recipes.length > 0 && (
//         <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
//           {recipes.map((recipe) => (
//             <div key={recipe.idMeal} style={{ border: '1px solid #ddd', borderRadius: '8px', width: '200px', padding: '10px' }}>
//               <h3>{recipe.strMeal}</h3>
//               <img src={recipe.strMealThumb} alt={recipe.strMeal} style={{ width: '100%', borderRadius: '8px' }} />
//               <p><strong>Category:</strong> {recipe.strCategory}</p>
//               <p><strong>Area:</strong> {recipe.strArea}</p>
//               <button onClick={() => window.open(recipe.strSource, '_blank')} style={{ marginTop: '10px', padding: '5px 10px' }}>
//                 View Recipe
//               </button>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// }

// export default RecipeGenerator;

