// Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-blue-600 p-1 ">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <a href="/" className="text-white text-2xl font-bold">
          MyLogo
        </a>

        {/* Hamburger Menu */}
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-white focus:outline-none"
          >
            {/* Hamburger icon */}
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>

        {/* Nav Links */}
        <div
          className={`flex-col md:flex-row md:flex ${
            isOpen ? 'flex' : 'hidden'
          } md:items-center md:space-x-6`}
        >
          <Link to="/" className="text-white py-2 px-4 block md:inline-block rounded-md text-white font-semibold hover:bg-blue-700 transition duration-200">
            Home
          </Link>
          <Link to="/home" className="text-white py-2 px-4 block md:inline-block rounded-md text-white font-semibold hover:bg-blue-700 transition duration-200">
            Ai Generator
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
