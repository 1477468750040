// // HomePage.js
// import React from 'react';

// const HomePage = () => {
//   return (
//     <div className="bg-gray-100 min-h-screen">
//       {/* Hero Section */}
//       <header className="bg-cover bg-center h-96" style={{ backgroundImage: `url('https://source.unsplash.com/featured/?food')` }}>
//         <div className="bg-black bg-opacity-50 h-full flex items-center justify-center">
//           <h1 className="text-white text-4xl md:text-6xl font-bold text-center">
//             Welcome to Recipe Haven
//           </h1>
//         </div>
//       </header>

//       {/* Search Bar */}
//       <section className="py-8 px-4 text-center">
//         <h2 className="text-2xl font-semibold mb-4">Find Your Favorite Recipes</h2>
//         <div className="flex justify-center">
//           <input
//             type="text"
//             placeholder="Search recipes..."
//             className="p-3 w-3/4 md:w-1/2 rounded-l-lg border border-gray-300 focus:outline-none"
//           />
//           <button className="bg-blue-600 text-white p-3 rounded-r-lg font-semibold hover:bg-blue-700">
//             Search
//           </button>
//         </div>
//       </section>

//       {/* Featured Recipes */}
//       <section className="py-8 px-4">
//         <h2 className="text-3xl font-semibold text-center mb-8">Featured Recipes</h2>
//         <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
//           {/* Sample recipe cards */}
//           {Array(6)
//             .fill(0)
//             .map((_, index) => (
//               <div
//                 key={index}
//                 className="bg-white shadow-md rounded-lg overflow-hidden"
//               >
//                 <img
//                   src={`https://source.unsplash.com/300x200/?recipe,food,dish${index}`}
//                   alt="Recipe"
//                   className="w-full h-48 object-cover"
//                 />
//                 <div className="p-4">
//                   <h3 className="text-lg font-semibold">Delicious Dish {index + 1}</h3>
//                   <p className="text-gray-600 mt-2">
//                     A short description of this delicious dish. Perfect for any occasion.
//                   </p>
//                   <button className="bg-blue-600 text-white w-full mt-4 p-2 rounded-lg font-semibold hover:bg-blue-700">
//                     View Recipe
//                   </button>
//                 </div>
//               </div>
//             ))}
//         </div>
//       </section>

//       {/* Footer */}
//       <footer className="bg-blue-600 text-white py-4 mt-8">
//         <div className="container mx-auto text-center">
//           <p className="text-sm">
//             &copy; {new Date().getFullYear()} Recipe Haven. All rights reserved.
//           </p>
//         </div>
//       </footer>
//     </div>
//   );
// };

// export default HomePage;


// HomePage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const HomePage = () => {
  const [recipes, setRecipes] = useState([]);
  const [cart, setCart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);

  // Fetch recipes from API
  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const response = await axios.get('https://www.themealdb.com/api/json/v1/1/search.php?f=a');
        setRecipes(response.data.meals);
      } catch (error) {
        console.error('Error fetching recipes:', error);
      }
    };

    fetchRecipes();
  }, []);

  // Add recipe to cart
  const addToCart = (recipe) => {
    setCart((prevCart) => [...prevCart, recipe]);
    setIsCartOpen(true); // Open cart when a recipe is added
  };

  // Remove recipe from cart
  const removeFromCart = (id) => {
    setCart((prevCart) => prevCart.filter((recipe) => recipe.idMeal !== id));
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <header className="bg-cover bg-center h-96" style={{ backgroundImage: `url('https://source.unsplash.com/featured/?food')` }}>
        <div className="bg-black bg-opacity-50 h-full flex items-center justify-center">
          <h1 className="text-white text-4xl md:text-6xl font-bold text-center">
            Discover Delicious Recipes
          </h1>
        </div>
      </header>

      {/* Search Bar */}
      <section className="py-8 px-4 text-center">
        <h2 className="text-2xl font-semibold mb-4">Find Your Favorite Recipes</h2>
        <div className="flex justify-center">
        </div>
      </section>

      {/* Recipes Grid */}
      <section className="py-8 px-4">
        <h2 className="text-3xl font-semibold text-center mb-8">Recipes</h2>
        <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          {recipes.map((recipe) => (
            <div key={recipe.idMeal} className="bg-white shadow-md rounded-lg overflow-hidden">
              <img
                src={recipe.strMealThumb}
                alt={recipe.strMeal}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold">{recipe.strMeal}</h3>
                <p className="text-gray-600 mt-2 line-clamp-2">{recipe.strInstructions}</p>
                <button
                  onClick={() => addToCart(recipe)}
                  className="bg-blue-600 text-white w-full mt-4 p-2 rounded-lg font-semibold hover:bg-blue-700"
                >
                  Add to Cart
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Cart Menu */}
      <div
        className={`fixed top-0 right-0 h-full w-80 bg-white shadow-lg transform transition-transform ${
          isCartOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="flex justify-between items-center p-4 border-b border-gray-200">
          <h3 className="text-lg font-semibold">Your Cart</h3>
          <button onClick={() => setIsCartOpen(false)} className="text-gray-600 hover:text-gray-800">
            Close
          </button>
        </div>
        <div className="p-4">
          {cart.length > 0 ? (
            cart.map((item) => (
              <div key={item.idMeal} className="flex items-center justify-between py-2 border-b">
                <span>{item.strMeal}</span>
                <button
                  onClick={() => removeFromCart(item.idMeal)}
                  className="text-red-500 hover:text-red-700"
                >
                  Remove
                </button>
              </div>
            ))
          ) : (
            <p className="text-gray-500">Your cart is empty.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
